import PropTypes from "prop-types"
import React, { useState, useCallback, useRef, useEffect } from "react"
useState, useCallback, useRef, useEffect
import {
  Container,
  Table,
  Collapse,
  Button,
  Row,
  Col,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Input,
} from "reactstrap"

import { useNavigate } from "react-router-dom"
import Select from "react-select"

import "chartist/dist/scss/chartist.scss"
import { DatePicker, DateRangePicker, Stack } from "rsuite"
const { combine } = DatePicker
//i18n
import { withTranslation } from "react-i18next"
//class
import chevleft from "assets/images/services-icon/chev-left.png"
import { useFormik } from "formik"
import * as yup from "yup"
import moment from "moment"

import axios from "axios"
import { BASE_URL } from "../../global"
//popup

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const LibraryDetails = props => {
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitting1, setIsSubmitting1] = useState(false)
  const formRef = useRef(null)

  const optionGroup3 = [
    { label: "Video", value: '1' },
    { label: "Link", value: '3'},
    { label: "Task", value: '4' },
    { label: "Work sheet", value: '5' },
  ]
  const optionGroupedit3 = [
    { label: "Video", value: 1 },
    { label: "Link", value: 3 },
    { label: "Task", value: 4 },
    { label: "Work sheet", value: 5 },
  ]

  const optionGroup4 = [
    // {
    //   label: "Select Duration",
    //   options: [
    { label: "Less than 2 minutes", value: "AlLess than 2 minutesl" },
    { label: "2-5 minutes", value: "2-5 minutes" },
    { label: "5-10 minutes", value: "5-10 minutes" },
    { label: "10-15 minutes", value: "10-15 minutes" },
    { label: "15-20 minutes", value: "15-20 minutes" },
    { label: "20-30 minutes", value: "20-30 minutes" },
    { label: "5-10 minutes", value: "5-10 minutes" },
    { label: "30-60 minutes", value: "30-60 minutes" },
    //   ],
    // },
  ]
  //accordion

  // const [col2, setCol2] = useState(false)
  // const [col3, setCol3] = useState(false)

  // function t_col1() {
  //   setCol1(!col1)
  //   //setCol1(true)
  //   setCol2(false)
  //   setCol3(false)
  // }
  // function t_col2() {
  //   setCol2(!col2)
  //   setCol1(false)
  //   //setCol2(true)
  //   setCol3(false)
  // }
  // function t_col3() {
  //   setCol1(false)
  //   setCol2(false)
  //   //setCol3(true)
  //   setCol3(!col3)
  // }

  //modal add
  const [modal_large, setmodal_large] = useState(false)
  const tog_large = () => {
    setmodal_large(!modal_large)
  }

  //modal edit
  const [modal_large1, setmodal_large1] = useState(false)
  const tog_large1 = () => {
    setmodal_large1(!modal_large1)
  }

  //delete modal
  const [modal_center, setmodal_center] = useState(false)
  const tog_center = () => {
    setmodal_center(!modal_center)
  }

  //tooltip
  const [ttop, setttop] = useState(false)

  const [ttop1, setttop1] = useState(false)

  // const [date, setDate] = useState(new Date());
  const [date, setDate] = useState([null, null])
  const [subject, setSubject] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  //const [Alldata, setAllData] = useState([]);
  const [dataTableData, setDataTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [singleMylibraryData, setSingleMyLibrary] = useState([])
  const [allEditMylibrarydata, setAllEditMylibrarydata] = useState([])
  const [allDeleteMylibrarydata, setAllDeleteMylibrarydata] = useState([])
  const [subjectdata, setSubjectData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [selectedOption3, setSelectedOption] = useState("")
  const [selectedOption3edit, setSelectedOptionedit] = useState("")
  const [inputValue, setInputValue] = useState("")
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false)

  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const fetchData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(
          BASE_URL + "mylibrary/getAllMyLibrary",
          {}
        )
        setDataTableData(response.data)
        setFilteredData(response.data)
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const handleRowEditActivityClick = async e => {
    console.log(e)
    if (authUser && authUser != "" && authUser != null) {
      // setLoading(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      const response = await axios
        .post(BASE_URL + "mylibrary/getSingleMyLibrary", {
          pk_tutor_library_item_key: e.pk_tutor_library_item_key,
        })
        .then(responsesinglemylibrary => {
          if (responsesinglemylibrary.data.length > 0) {
            setAllEditMylibrarydata(responsesinglemylibrary.data)
          } else {
            toast.error(
              "Failure! Unable to Editable session. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            )
          }
        })
        .catch(error => {
          toast.error("Failure! Unable to Editable session. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          })
        })
    }
    // console.log(e.sest_from_date);
    // console.log(e.sest_start_time);
  }

  const fetchData2 = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseSubject = await axios.post(
          BASE_URL + "mylibrary/getSubject",
          {}
        )

        const subjectOptions = responseSubject.data.map(option => ({
          value: option.subject_id,
          label: option.sub_name,
        }))
        setSubjectData(subjectOptions)
      } else {
        navigate("/login")
      } /*  */
      // setAllData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }
  // const fetchData3 = async () => {
  //   try {
  //     if (authUser && authUser != "" && authUser != null) {
  //       // setLoading(true);
  //       axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
  //       const response = await axios.post(BASE_URL+'mylibrary/getSingleMyLibrary', {

  //       });
  //       setAllEditMylibrarydata(response.data);

  //     }
  //     else {
  //       navigate("/login");
  //     }/*  */
  //     // setAllData(response.data);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }

  // };

  useEffect(() => {
    fetchData()
    fetchData2()
    // fetchData3();
  }, [date, subject, type, status])

  useEffect(() => {
    const activitytype = allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].activity_type
      : ""

    setSelectedOptionedit(activitytype)
  }, [allEditMylibrarydata])
  
  /* Add activity form function*/

  const basicSchema = yup.object().shape({
    act_subject: yup.string().required("Subject name is required."),
  })

  const initialValues = {
    /*subjects*/

    act_subject: "",
    act_type: "",
    act_name: "",
    act_source: "",
    act_duration: "",
    act_file: null,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    //  onSubmit,
    onSubmit: async formValues => {
      // setIsSubmitting(true);

      try {
        if (authUser && authUser != "" && authUser != null) {
          // setLoading(true);

          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`

          if (values.act_file) {
            const formData = new FormData()
            formData.append("act_subject", values.act_subject)
            formData.append("act_type", values.act_type)
            formData.append("act_name", values.act_name)
            formData.append("act_source", values.act_source)
            formData.append("act_duration", values.act_duration)
            formData.append("act_file", values.act_file)

            const response = await axios.post(
              BASE_URL + "mylibrary/createMyLibrary1",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            if (response.data.status && response.data.status === true) {
              // Reset the form
              //formRef.current.reset();
              fetchData()
              toast.success(`Activity added to My Library`, {
                autoClose: 3000, // 3 seconds in this example
              })
              setmodal_large(false)

              // After 3 seconds, redirect to another page
              // setTimeout(function () {
              //   navigate("/Subject");
              // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

              setIsSubmitting(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to create activity in my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting(false) // Re-enable the submit button
            }
          } else {
            const response = await axios.post(
              BASE_URL + "mylibrary/createMyLibrary",
              {
                ...formValues,
              }
            )

            if (response.data.status && response.data.status === true) {
              // Reset the form
              //formRef.current.reset();
              fetchData()
              toast.success(`Activity added to My Library`, {
                autoClose: 3000, // 3 seconds in this example
              })
              setmodal_large(false)

              // After 3 seconds, redirect to another page
              // setTimeout(function () {
              //   navigate("/Subject");
              // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

              setIsSubmitting(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to create activity in my library. Try again laters",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting(false) // Re-enable the submit button
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        toast.error(
          "Failure! Unable to create activity in my library. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        )

        setIsSubmitting(false) // Re-enable the submit button
      } finally {
        setIsSubmitting(false) // Re-enable the submit button
      }
    },
  })

 
  // Now, you can set variables as needed:
  const values = formik.values
  const handleBlur = formik.handleBlur
  const touched = formik.touched
  const handleChange = formik.handleChange
  const handleFileChange = formik.handleChange

  const errors = formik.errors
  const handleSubmit = formik.handleSubmit
  const setTouched = formik.setTouched
  const resetForm = formik.resetForm
  const setValues = formik.setValues
  const handleReset = formik.handleReset

  /* Edit activity form function*/

  const basicSchema1 = yup.object().shape({
    act_subject: yup.string().required("Subject name is required."),
  })

  // activity_type
  // created_on
  // deleted_on
  // duration
  // fk_activity_topic_id
  // fk_sub_id
  // fk_tut_key
  // is_deleted
  // is_hide
  // link
  // name
  // pk_tutor_library_item_id
  // pk_tutor_library_item_key
  // source
  // updated_on

  const initialValues1 = {
    /*subjects*/
    // pk_tutor_library_item_key: allEditMylibrarydata ? allEditMylibrarydata.pk_tutor_library_item_key : '',
    act_subject: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].fk_sub_id
      : "",
    act_type: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].activity_type
      : "",
    act_name: allEditMylibrarydata[0] ? allEditMylibrarydata[0].name : "",
    act_source: allEditMylibrarydata[0] ? allEditMylibrarydata[0].source : "",
    act_duration: allEditMylibrarydata[0]
      ? allEditMylibrarydata[0].duration
      : "",
    act_link:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 3
        ? allEditMylibrarydata[0].link
        : "",
    act_video:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 1
        ? allEditMylibrarydata[0].link
        : "",
    act_file1:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 5
        ? allEditMylibrarydata[0].link
        : "",
    act_file:
      allEditMylibrarydata[0] && allEditMylibrarydata[0].activity_type === 5
        ? allEditMylibrarydata[0].link
        : "",
  }
  // console.log('esf',allEditMylibrarydata)

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: basicSchema1,
    enableReinitialize: true,
    //  onSubmit,
    onSubmit: async formValues => {
      try {
        if (authUser && authUser != "" && authUser != null) {
          // setLoading(true);
          axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
          if (values1.act_file instanceof File) {
            const formData = new FormData()
            formData.append(
              "pk_tutor_library_item_key",
              allEditMylibrarydata[0].pk_tutor_library_item_key
            )
            formData.append("act_subject", values1.act_subject)
            formData.append("act_type", values1.act_type)
            formData.append("act_name", values1.act_name)
            formData.append("act_source", values1.act_source)
            formData.append("act_duration", values1.act_duration)
            formData.append("act_file", values1.act_file)

            const response = await axios.post(
              BASE_URL + "mylibrary/updateMyLibrary1",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )

            // const response = await axios.put(BASE_URL+'mylibrary/updateMyLibrary', {
            //   pk_tutor_library_item_key: allEditMylibrarydata[0].pk_tutor_library_item_key,
            //   ...formValues
            // });
            // console.log("response", response)

            if (response.data.status && response.data.status === true) {
              // Reset the form
             

              // console.log(response)
              toast.success("Activity updated successfully", {
                autoClose: 3000,
              })
              fetchData()
              setmodal_large1(false)
              setIsSubmitting1(false)
            } else {
              formRef.current.reset()
              toast.error(
                "Failure! Unable to update the activity in my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting1(false) // Re-enable the submit button
            }
          } else {
            const response = await axios.post(
              BASE_URL + "mylibrary/updateMyLibrary",
              {
                pk_tutor_library_item_key:
                  allEditMylibrarydata[0].pk_tutor_library_item_key,
                ...formValues,
              }
            )

            // Reset the form
            //formRef.current.reset();

            if (response.data.status && response.data.status === true) {
              // console.log(response)
              toast.success("Activity updated successfully", {
                autoClose: 3000, // 3 seconds in this example
              })
              fetchData()
              setmodal_large1(false)
              setIsSubmitting1(false) // Re-enable the submit button
            } else {
              toast.error(
                "Failure! Unable to update the activity in my library. Try again later",
                {
                  autoClose: 3000, // 3 seconds in this example
                }
              )

              setIsSubmitting1(false) // Re-enable the submit button
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error)
        toast.error(
          "Failure! Unable to update the activity in my library. Try again later",
          {
            autoClose: 3000, // 3 seconds in this example
          }
        )

        setIsSubmitting1(false) // Re-enable the submit button
      } finally {
        setIsSubmitting1(false) // Re-enable the submit button
      }
    },
  })

  
  // Now, you can set variables as needed:
  const values1 = formik1.values
  const handleBlur1 = formik1.handleBlur
  const touched1 = formik1.touched
  const handleChange1 = formik1.handleChange
  const errors1 = formik1.errors
  const handleSubmit1 = formik1.handleSubmit
  const setTouched1 = formik1.setTouched
  const resetForm1 = formik1.resetForm
  const setValues1 = formik1.setValues
  const handleReset1 = formik1.handleReset

  const handleRemoveFile = () => {
    formik1.setFieldValue("act_file1", "")
  }
  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true)

    if (authUser && authUser != "" && authUser != null) {
      // setLoading(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
      const response = axios
        .post(BASE_URL + "mylibrary/deleteMyLibrary", {
          pk_tutor_library_item_key: allDeleteMylibrarydata,
        })
        .then(responseuserdelete => {
          // console.log(responseuserdelete)
          if (responseuserdelete.data.status == true) {
            // Reset the form
            //formRef.current.reset();

            toast.success("Activity deleted successfully", {
              autoClose: 3000, // 3 seconds in this example
            })

            fetchData()
            setmodal_center(false)
            setIsUserDeleteSubmitting(false)

            // } else if (responseuserdelete.data.data === 2) {
            //   toast.error("Failure! Unable to delete session. student enrolled this session", {
            //     autoClose: 3000, // 3 seconds in this example
            //   });
            //   fetchData();
            //   setDeleteShow(false);
            //   setIsUserDeleteSubmitting(false);
          } else {
            toast.error(
              "Failure! Unable to delete the activity from my library. Try again later",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            )
            setIsUserDeleteSubmitting(false)
          }
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          toast.error("Failure! Unable to delete activity. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          })
          setIsUserDeleteSubmitting(false)
        })
    }
  }

  // const resetFormFields = (resetForm, setValues, initialValues) => {
  //   resetForm(); // Reset Formik-controlled fields
  // };

  // function handleClick(event) {
  //   event.preventDefault();
  //   console.info('You clicked a breadcrumb.');
  // }

  const [openItems, setOpenItems] = useState(
    Array(dataTableData.length).fill(true)
  )

  // Function to toggle the open/close state of a specific item
  const toggleItem = index => {
    const newOpenItems = [...openItems]
    newOpenItems[index] = !newOpenItems[index]
    setOpenItems(newOpenItems)
  }

  const handleInputChange = event => {
    setSelectedOption(event.target.value)
    setInputValue("")
  }

  const handleInputChangeEdit = event => {
    
    setSelectedOptionedit(event.target.value)
    setInputValue("")
    
  }
  

  const handleInputValueChange = event => {
    setInputValue(event.target.value)
  }

  const filterReset = () => {
    setDateValue([null, null])
    setfilterValue("")
    fetchData()
  }

  // function handleFilter(event) {
  //   const inputValue = event.target.value.trim().toLowerCase()
  //   const newData = dataTableData.filter(row => {
  //     return (
  //       (row.TutorLibraryItems.created_on != null || row.TutorLibraryItems.created_on != ""
  //         ? moment(row.created_on, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
  //             "DD-MM-YYYY"
  //           )
  //         : "-"
  //       ).includes(inputValue) ||
  //       (row.TutorLibraryItems.name != null || row.TutorLibraryItems.name != "" ? row.TutorLibraryItems.name : "-")
  //         .toLowerCase()
  //         .includes(inputValue) ||
  //         (row.TutorLibraryItems.source != null || row.TutorLibraryItems.source != "" ? row.TutorLibraryItems.source : "-")
  //         .toLowerCase()
  //         .includes(inputValue) ||
  //         (row.TutorLibraryItems.duration != null || row.TutorLibraryItems.duration != "" ? row.TutorLibraryItems.duration : "-")
  //         .toLowerCase()
  //         (row.TutorLibraryItems.activity_type != null || row.TutorLibraryItems.activity_type != "" ? row.TutorLibraryItems.activity_type : "-")
  //         .toLowerCase()
  //           .includes(inputValue)
  //     )
  //   })
  //   setDataTableData(newData)
  // }

  // const handleFilter = (event) => {
  //   const inputValue = event.target.value.trim().toLowerCase();

  //   if (inputValue === '') {
  //     setDataTableData(filteredData); // Reset to original data
  //     return; // Exit early
  //   }

  //   const newData = filteredData.filter(item => {
  //     // Filter based on TutorLibraryItems
  //     const filteredItems = item.TutorLibraryItems.filter(subItem => {
  //       const itemName = subItem.name ? subItem.name.toLowerCase() : '';
  //       const itemSource = subItem.source ? subItem.source.toLowerCase() : '';
  //       const itemDuration = subItem.duration ? subItem.duration.toLowerCase() : '';
  //       // const itemActivityType = subItem.activity_type ? subItem.activity_type.toLowerCase() : '';
  //       const itemCreatedOn = subItem.created_on ? moment(subItem.created_on, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY').toLowerCase() : '';

  //       return (
  //         itemName.includes(inputValue) ||
  //         itemSource.includes(inputValue) ||
  //         itemDuration.includes(inputValue) ||
  //         // itemActivityType.includes(inputValue) ||
  //         itemCreatedOn.includes(inputValue)
  //       );
  //     });

  //     // Update item with filtered TutorLibraryItems
  //     return { ...item, TutorLibraryItems: filteredItems };
  //   });

  //   console.log(newData);
  //   setDataTableData(newData); // Update filtered data
  // };
  const [filterValue, setfilterValue] = useState("")
  const handleFilter = event => {
    setfilterValue(event.target.value)
    const inputValue = event.target.value.trim().toLowerCase()

    // Filter data based on search text
    const newData = filteredData.map(row => {
      const filteredItems = row.TutorLibraryItems.filter(subItem => {
        const itemName = subItem.name ? subItem.name.toLowerCase() : ""
        const itemSource = subItem.source ? subItem.source.toLowerCase() : ""
        const itemDuration = subItem.duration
          ? subItem.duration.toLowerCase()
          : ""
        const itemActivityType = subItem.activity_type
          ? subItem.activity_type
          : ""
        const itemCreatedOn = subItem.created_on
          ? moment(subItem.created_on, "YYYY-MM-DDTHH:mm:ssZ")
              .format("DD MMM YYYY")
              .toLowerCase()
          : ""
        // Additional filter condition based on sest_is_holidays
        const holidayType = itemActivityType
          ? itemActivityType == "1"
            ? "Video"
            : itemActivityType == "3"
            ? "Link"
            : itemActivityType == "4"
            ? "Task"
            : itemActivityType == "5"
            ? "WorkSheet"
            : "-"
          : "-"

        return (
          itemName.includes(inputValue) ||
          itemSource.includes(inputValue) ||
          itemDuration.includes(inputValue) ||
          itemCreatedOn.includes(inputValue) ||
          holidayType.toLowerCase().includes(inputValue)
        )
      })

      return { ...row, TutorLibraryItems: filteredItems }
    })

    //console.log(newData); // Check console for filtered data
    setDataTableData(newData) // Update filtered data
  }

  const [dateValue, setDateValue] = useState([null, null])
  const handleDateChange = value => {
    // Do something with the selected date range

    // const filterStartDate= value[0] ? moment.utc(dateValue[0]).format('YYYY-MM-DD') : null;
    // const filterEndDate= value[1] ? moment.utc(dateValue[1]).format('YYYY-MM-DD') : null;

    // // Filter data based on search text
    // const newData = filteredData.map(row => {
    //   const filteredItems = row.TutorLibraryItems.filter(subItem => {

    //     const itemCreatedOn = subItem.created_on
    //       ? moment(subItem.created_on, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY').toLowerCase()
    //       : '';

    //     return (
    //       itemCreatedOn.includes(inputValue)
    //     );
    //   });

    //   return { ...row, TutorLibraryItems: filteredItems };
    // });
    // setDataTableData(newData); // Update filtered data

    const filterStartDate = value[0]
      ? moment.utc(value[0]).startOf("day")
      : null
    const filterEndDate = value[1] ? moment.utc(value[1]).endOf("day") : null

    const newData = filteredData.map(row => {
      const filteredItems = row.TutorLibraryItems.filter(subItem => {
        const itemCreatedOn = subItem.created_on
          ? moment(subItem.created_on, "YYYY-MM-DDTHH:mm:ssZ")
          : null

        // Check if the created_on date is between filterStartDate and filterEndDate
        return (
          itemCreatedOn &&
          (!filterStartDate || itemCreatedOn.isSameOrAfter(filterStartDate)) &&
          (!filterEndDate || itemCreatedOn.isSameOrBefore(filterEndDate))
        )
      })

      return { ...row, TutorLibraryItems: filteredItems }
    })

    setDataTableData(newData) // Update filtered data
    setDateValue(value)
  }

  // const handleFilter = (event) => {
  //   const inputValue = event.target.value.trim().toLowerCase();
  //   setFilterValue(inputValue); // Update filter input value

  //   // Filter data based on input value without resetting data
  //   const newData = dataTableData.filter(row => {
  //     // Filter by parent subject name
  //     const subjectName = row.subject_name.toLowerCase().includes(inputValue);

  //     // Filter by TutorLibraryItems properties
  //     const filteredItems = row.TutorLibraryItems.filter(item => {
  //       const nameMatch = item.name && item.name.toLowerCase().includes(inputValue);
  //       const sourceMatch = item.source && item.source.toLowerCase().includes(inputValue);
  //       const durationMatch = item.duration && item.duration.toLowerCase().includes(inputValue);
  //       const createdOnMatch = item.created_on && moment(item.created_on, "YYYY-MM-DDTHH:mm:ss.SSSZ")
  //         .format("DD-MM-YYYY")
  //         .includes(inputValue);

  //       // Return true if any of the conditions match
  //       return nameMatch || sourceMatch || durationMatch || createdOnMatch;
  //     });

  //     // Include rows where either the subject name or any TutorLibraryItems match
  //     return subjectName || filteredItems.length > 0;
  //   });

  //   setDataTableData(newData); // Update displayed data
  // };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <div>
              <div className="d-flex align-items-center">
                <div className="short-ic" onClick={() => window.history.back()}>
                  <img className="bc-bg2 mr-30" src={chevleft} />
                </div>

                <div>
                  <h6
                    className="page-title inner-pg-title mb-0"
                    style={{ width: "20vw" }}
                  >
                    {" "}
                    My Library
                  </h6>
                </div>
              </div>
            </div>
            <div className="custom-pg_header justify-content-between mb-3 align-items-center pdt-15">
              <div className="filter-section filter-libary pymts d-flex gap-3 mt-0">
                <form className="app-search page-search mylibary">
                  <div className="position-relative">
                    <span className="ion ion-ios-search"></span>
                    <input
                      type="text"
                      className="form-control"
                      value={filterValue}
                      placeholder={"Search for Activity Name..."}
                      onChange={handleFilter}
                    />
                  </div>
                </form>
                <div className="filter-child">
                  <DateRangePicker
                    format="dd-MM-yyyy"
                    value={dateValue}
                    onChange={handleDateChange}
                    cleanable={false}
                  />
                </div>
                <div className="filter-child">
                  <Button className="p-2" color="primary" onClick={filterReset}>
                    Clear Filter
                  </Button>
                </div>
              </div>

              {/* <form className="app-search page-search">
                <div className="position-relative">
                  <span className="fa fa-search"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={props.t("Search for activity name") + "..."}
                  />
                </div>
              </form> */}

              <div className="cts-btn-danger" onClick={tog_large}>
                Add New Activity
              </div>

              <Modal isOpen={modal_large} toggle={tog_large} size="lg">
                <ModalHeader className="mt-0" toggle={tog_large}>
                  Add New Activity
                </ModalHeader>
                <ModalBody>
                  <Form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    ref={formRef}
                  >
                    <Row className="mb-3 g-3">
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Subject
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={subjectdata}
                              name="act_subject"
                              value={subjectdata.find(
                                option =>
                                  option.value &&
                                  values.act_subject &&
                                  option.value.toString() ===
                                    values.act_subject.toString()
                              )}
                              id="act_subject"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_subject",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                                handleInputChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_subject: true })
                              }}
                              className={
                                errors.act_subject && touched.act_subject
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_subject && touched.act_subject && (
                              <p className="error">{errors.act_subject}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Activity type
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={optionGroup3}
                              name="act_type"
                              value={optionGroup3.find(
                                option =>
                                  option.value &&
                                  values.act_type &&
                                  option.value.toString() ===
                                    values.act_type.toString()
                              )}
                              id="act_type"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                                handleInputChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_type: true })
                              }}
                              className={
                                errors.act_type && touched.act_type
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_type && touched.act_type && (
                              <p className="error">{errors.act_type}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Activity name
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values.act_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="act_name"
                              className={
                                errors.act_name && touched.act_name
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors.act_name && touched.act_name && (
                              <p className="error">{errors.act_name}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Source
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values.act_source}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="act_source"
                              className={
                                errors.act_source && touched.act_source
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors.act_source && touched.act_source && (
                              <p className="error">{errors.act_source}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Duration
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={optionGroup4}
                              name="act_duration"
                              value={optionGroup4.find(
                                option =>
                                  option.value &&
                                  values.act_duration &&
                                  option.value.toString() ===
                                    values.act_duration.toString()
                              )}
                              id="act_duration"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_duration",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange(event)
                              }}
                              onBlur={event => {
                                handleBlur(event)
                                setTouched({ ...touched, act_duration: true })
                              }}
                              className={
                                errors.act_duration && touched.act_duration
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors.act_duration && touched.act_duration && (
                              <p className="error">{errors.act_duration}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      {selectedOption3 === "1" && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Video
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values.act_video}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="act_video"
                                className={
                                  errors.act_video && touched.act_video
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors.act_video && touched.act_video && (
                                <p className="error">{errors.act_video}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {selectedOption3 === "3" && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Link
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values.act_link}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="act_link"
                                className={
                                  errors.act_link && touched.act_link
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors.act_link && touched.act_link && (
                                <p className="error">{errors.act_link}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {selectedOption3 === "5" && (
                        <Col className="col-12">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Upload PDF
                            </label>

                            <span> File size should not exceed 2mb</span>
                            <div className="">
                              <Input
                                type="file"
                                accept=".pdf"
                                onChange={event => {
                                  formik.setFieldValue(
                                    "act_file",
                                    event.currentTarget.files[0]
                                  )
                                }}
                                onBlur={handleBlur}
                                id="act_file"
                                name="act_file"
                                className={
                                  errors.act_file && touched.act_file
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      )}
                      {/* {selectedOption === 'Date' && (
   <input
     type="date"
     value={inputValue}
     onChange={handleInputValueChange}
     placeholder="Select date..."
   />
 )} */}
                    </Row>
                    <div className="text-end mt-4">
                      {/* <Button type="submit" color="danger">
                        Add
                      </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        color="danger"
                        disabled={isSubmitting}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal isOpen={modal_large1} toggle={tog_large1} size="lg">
                <ModalHeader className="mt-0" toggle={tog_large1}>
                  Edit New Activity
                </ModalHeader>
                <ModalBody>
                  <Form
                    autoComplete="off"
                    onSubmit={handleSubmit1}
                    ref={formRef}
                  >
                    <Row className="mb-3 g-3">
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Subject
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={subjectdata}
                              name="act_subject"
                              value={subjectdata.find(
                                option =>
                                  option.value &&
                                  values1.act_subject &&
                                  option.value.toString() ===
                                    values1.act_subject.toString()
                              )}
                              id="act_subject"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_subject",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange1(event)
                              }}
                              onBlur={event => {
                                handleBlur1(event)
                                setTouched1({ ...touched1, act_subject: true })
                              }}
                              className={
                                errors1.act_subject && touched1.act_subject
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors1.act_subject && touched1.act_subject && (
                              <p className="error">{errors1.act_subject}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className=" col-form-label"
                          >
                            Activity type
                          </label>
                          <div className="">
                            {/* <Select
                              menuPlacement="auto"
                              options={optionGroup3}
                              name="act_type"
                              value={optionGroup3.find(options => options.value === values1.act_type)}
                              id="act_type"
                              onChange={(selectedOption) => {
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedOption ? selectedOption.value : "", // Ensure a valid value is set
                                  },
                                };
                                handleChange1(event);
                              }}
                              onBlur={(event) => {
                                handleBlur1(event);
                                setTouched1({ ...touched1, act_type: true });
                              }}
                              className={errors1.act_type && touched1.act_type ? "input-error" : ""}
                            /> */}

                            <Select
                              menuPlacement="auto"
                              options={optionGroupedit3}
                              name="act_type"
                              value={optionGroupedit3.find(
                                option =>
                                  option.value &&
                                  values1.act_type &&
                                  option.value.toString() ===
                                    values1.act_type.toString()
                              )}
                              id="act_type"
                              onChange={selectedOption => {
                                const selectedValue = selectedOption
                                  ? selectedOption.value
                                  : "" // Ensure a valid value is set
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedValue,
                                  },
                                }

                                // Check the selected value and set a specific value based on a condition
                                let newValue = selectedValue // Default to the selected value
                                if (selectedValue === "desiredValue") {
                                  // Change 'desiredValue' to your condition
                                  newValue = "newValue" // Set the new value based on the condition
                                }

                                // Set the new value before calling handleInputChangeEdit
                                event.target.value = newValue
                                handleChange1(event)

                                handleInputChangeEdit(event)
                              }}
                              onBlur={event => {
                                handleBlur1(event)
                                setTouched1({ ...touched1, act_type: true })
                              }}
                              className={
                                errors1.act_type && touched1.act_type
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {/* <Select
                              menuPlacement="auto" options={optionGroup3}
                              name="act_type"
                              value={optionGroup3.find(options => options.value && values1.act_type && options.value == values1.act_type)}
                              id="act_type"
                              onChange={(selectedOption) => {
                                const event = {
                                  target: {
                                    name: "act_type",
                                    value: selectedOption["value"],
                                  },
                                };
                                handleChange1(event);
                              }}
                              onBlur={(event) => {
                                handleBlur1(event);
                                setTouched1({ ...touched1, act_type: true });
                              }}
                              className={errors1.act_type && touched1.act_type ? "input-error" : ""}
                            /> */}
                            {errors1.act_type && touched1.act_type && (
                              <p className="error">{errors1.act_type}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Activity name
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values1.act_name}
                              onChange={handleChange1}
                              onBlur={handleBlur1}
                              id="act_name"
                              className={
                                errors1.act_name && touched1.act_name
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors1.act_name && touched1.act_name && (
                              <p className="error">{errors1.act_name}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Source
                          </label>
                          <div className="">
                            <Input
                              type="text"
                              value={values1.act_source}
                              onChange={handleChange1}
                              onBlur={handleBlur1}
                              id="act_source"
                              className={
                                errors1.act_source && touched1.act_source
                                  ? "form-control input-error"
                                  : ""
                              }
                            />
                            {errors1.act_source && touched1.act_source && (
                              <p className="error">{errors1.act_source}</p>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <label
                            htmlFor="example-text-input"
                            className="col-form-label"
                          >
                            Duration
                          </label>
                          <div className="">
                            <Select
                              menuPlacement="auto"
                              options={optionGroup4}
                              name="act_duration"
                              value={optionGroup4.find(
                                option =>
                                  option.value &&
                                  values1.act_duration &&
                                  option.value.toString() ===
                                    values1.act_duration.toString()
                              )}
                              id="act_duration"
                              onChange={selectedOption => {
                                const event = {
                                  target: {
                                    name: "act_duration",
                                    value: selectedOption["value"],
                                  },
                                }
                                handleChange1(event)
                              }}
                              onBlur={event => {
                                handleBlur1(event)
                                setTouched1({ ...touched1, act_duration: true })
                              }}
                              className={
                                errors1.act_duration && touched1.act_duration
                                  ? "input-error"
                                  : ""
                              }
                            />
                            {errors1.act_duration && touched1.act_duration && (
                              <p className="error">{errors1.act_duration}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                      {console.log("tyr",selectedOption3edit)}
                      {selectedOption3edit === 1 && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Video
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values1.act_video || ""}
                                onChange={handleChange1}
                                onBlur={handleBlur}
                                id="act_video"
                                className={
                                  errors1.act_video && touched1.act_video
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors1.act_video && touched1.act_video && (
                                <p className="error">{errors1.act_video}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {selectedOption3edit === 3 && (
                        <Col className="col-6">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Link
                            </label>
                            <div className="">
                              <Input
                                type="text"
                                value={values1.act_link || ""}
                                onChange={handleChange1}
                                onBlur={handleBlur}
                                id="act_link"
                                className={
                                  errors1.act_link && touched1.act_link
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                              {errors1.act_link && touched1.act_link && (
                                <p className="error">{errors1.act_link}</p>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                      {/* {selectedOption3edit === 5 && (
                        <Col className="col-12">
                          <div className="">
                            <label
                              htmlFor="example-text-input"
                              className="col-form-label"
                            >
                              Upload PDF
                            </label>

                            <span> File size should not exceed 2mb</span>
                            <div className="">
                              <Input
                                type="file"
                                accept=".pdf"
                                onChange={event => {
                                  formik1.setFieldValue(
                                    "act_file",
                                    event.currentTarget.files[0]
                                  )
                                }}
                                onBlur={handleBlur}
                                id="act_file"
                                name="act_file"
                                className={
                                  errors1.act_file && touched1.act_file
                                    ? "form-control input-error"
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      )} */}
                      {selectedOption3edit === 5 && (
                        <div className="">
                          {values1.act_file1 ? (
                            <div>
                              <label
                                htmlFor="example-text-input"
                                className="col-form-label"
                              >
                                Uploaded PDF
                              </label>
                              <div className="uploaded-file-info">
                                <span>{values1.act_file1 }</span>
                                <button
                                  type="button"
                                  onClick={handleRemoveFile}
                                  className="close-icon"
                                >
                                  &times; {/* Close icon */}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <Col className="col-12">
                              <div className="">
                                <label
                                  htmlFor="example-text-input"
                                  className="col-form-label"
                                >
                                  Upload PDF
                                </label>

                                <span> File size should not exceed 2mb</span>
                                <div className="">
                                  <Input
                                    type="file"
                                    accept=".pdf"
                                    onChange={event => {
                                      formik1.setFieldValue(
                                        "act_file",
                                        event.currentTarget.files[0]
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    id="act_file"
                                    name="act_file"
                                    className={
                                      errors1.act_file && touched1.act_file
                                        ? "form-control input-error"
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          )}
                        </div>
                      )}
                    </Row>
                    <div className="text-end mt-4">
                      {/* <Button type="submit" color="danger">
                        Add
                      </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        color="danger"
                        disabled={isSubmitting1}
                      >
                        Edit
                      </Button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalBody className="delete-pp text-center">
                  <div className="mm-icon text-danger">
                    <i className="fas fa-exclamation-circle"></i>
                  </div>
                  <h5>Delete Confirmation</h5>
                  <p>Are you sure you want to delete this activity?</p>

                  <div className="btn-group mt-4">
                    <Button
                      className="btn-md me-2"
                      color="danger"
                      disabled={isUserDeleteSubmitting}
                      onClick={() => onSubmitUserDelete()}
                    >
                      Yes
                    </Button>

                    <Button
                      className="btn-md btn-outline-danger"
                      color=""
                      onClick={() => setmodal_center(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-2 cp-cs-pd">
              <thead>
                <tr>
                  <th width="400">
                    <h6 class="text-dark">Activity Name</h6>
                  </th>
                  <th width="300">
                    <h6 class="text-dark">Source</h6>
                  </th>
                  <th width="300">
                    <h6 class="text-dark">Duration</h6>
                  </th>
                  <th width="300">
                    <h6 class="text-dark">Type</h6>
                  </th>
                  <th width="300">
                    <h6 class="text-dark">Date Added</h6>
                  </th>
                  <th width="200" className="text-end">
                    <h6 class="text-dark"></h6>
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
          <div className="accordion" id="accordion">
            {dataTableData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2
                  className="accordion-header"
                  id={`heading${item.subject_id}`}
                >
                  <button
                    // className={classnames(
                    //   {
                    //     collapsed: col1 === false,
                    //   },
                    //   "accordion-button fw-semibold"
                    // )}
                    className={`accordion-button fw-semibold  ${
                      openItems[index] && "collapsed"
                    }`}
                    type="button"
                    onClick={() => toggleItem(index)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.subject_name}
                  </button>
                </h2>

                <Collapse
                  isOpen={openItems[index]}
                  className={`accordion-collapse ${openItems[index] && "show"}`}
                >
                  <div className="accordion-body p-0">
                    <div className="table-responsive">
                      <Table className="table mb-0 cp-cs-pd">
                        <tbody>
                          {item.TutorLibraryItems.map((items, indexs) => (
                            <tr>
                              <th scope="row" width="400">
                                {items.name == null || items.name == ""
                                  ? "-"
                                  : items.name}
                              </th>
                              <td width="300">
                                {items.source == null || items.source == ""
                                  ? "-"
                                  : items.source}
                              </td>
                              <td width="300">
                                {" "}
                                {items.duration == null || items.duration == ""
                                  ? "-"
                                  : items.duration}
                              </td>
                              <td width="300">
                                {items.activity_type == null ||
                                items.activity_type == ""
                                  ? "-"
                                  : items.activity_type == "1"
                                  ? "Video"
                                  : items.activity_type == "3"
                                  ? "Link"
                                  : items.activity_type == "4"
                                  ? "Task"
                                  : items.activity_type == "5"
                                  ? "WorkSheet"
                                  : "-"}
                              </td>
                              <td width="300">
                                {items.created_on == null ||
                                items.created_on == ""
                                  ? "-"
                                  : moment(
                                      items.created_on,
                                      "YYYY-MM-DDTHH:mm:ssZ"
                                    ).format("DD MMM YYYY")}
                              </td>
                              <td width="200" className="text-end">
                                <Tooltip
                                  placement="top"
                                  isOpen={ttop}
                                  target="TooltipTop"
                                  toggle={() => {
                                    setttop(!ttop)
                                  }}
                                >
                                  Edit
                                </Tooltip>
                                <button
                                  onClick={() => {
                                    handleRowEditActivityClick(items)
                                    tog_large1()
                                  }}
                                  id="TooltipTop"
                                  className="btn btn-action btn-sm me-1"
                                >
                                  <i className="fas fa-pen"></i>
                                </button>

                                <Tooltip
                                  placement="top"
                                  isOpen={ttop1}
                                  target="TooltipTop1"
                                  toggle={() => {
                                    setttop1(!ttop1)
                                  }}
                                >
                                  Delete
                                </Tooltip>
                                <button
                                  id="TooltipTop1"
                                  onClick={() => {
                                    setAllDeleteMylibrarydata(
                                      items.pk_tutor_library_item_key
                                    )
                                    tog_center()
                                  }}
                                  className="btn btn-action btn-sm"
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

LibraryDetails.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LibraryDetails)
