import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import {
  Container,
  Table,
  Card,
  CardBody,
  Button,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  TabContent,
  TabPane,
  Progress,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { DateRangePicker } from "rsuite"
import Select from "react-select"
import moment from "moment"

import { Pie } from "react-chartjs-2"
import { Line } from "react-chartjs-2"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import "chartist/dist/scss/chartist.scss"
import TableComm from "../../dataTableCommon"
// import MUIDataTable from "mui-datatables"
// import Grid from "@mui/material/Grid"
// // import { DataGrid } from '@mui/x-data-grid';
// import LinearProgress from "@mui/material/LinearProgress"
// import Box from "@mui/material/Box"
// import {
//   GridRowModes,
//   DataGrid,
//   GridToolbar,
//   GridRowParams,
//   GridToolbarContainer,
//   GridActionsCellItem,
//   GridRowEditStopReasons,
// } from "@mui/x-data-grid"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "react-spinner-loader"
import { SECURITY_KEY, BASE_URL } from "../../global"
//datatable

import BootstrapTable from "react-bootstrap-table-next"
// import cellEditFactory from "react-bootstrap-table2-editor"
import paginationFactory from "react-bootstrap-table2-paginator"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"

import arrow from "assets/images/rightarrow.png"
import st1 from "assets/images/services-icon/st1.png"
import chevleft from "assets/images/services-icon/chev-left.png"
import sort from "assets/images/sort.png"

import classnames from "classnames"

const StudentList = props => {
  //datatable

  const today = new Date()
  const sixMonthsAgo = new Date()
  sixMonthsAgo.setMonth(today.getMonth() - 6)

  const [dateValue, setDateValue] = useState([sixMonthsAgo, today])

  const [searchValue, setsearchValue] = useState("")
  const [selectedGroup, setselectedGroup] = useState("")
  const [loading, setLoading] = useState(true)
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const [selectedYearLevelTopic, setselectedYearLvel] = useState("")

  function handleSelectYearLevelTopic(selectedGroup) {
    console.log(selectedGroup)
    setselectedYearLvel(selectedGroup)

    filterData(selectedGroup, selectedMastryScore)
  }
  // const handleSelectYearLevelTopic = selectedGroup => {
  //   if (selectedGroup === "all") {
  //     setMistryTopic(Alldata)
  //   } else {
  //     // Filter data based on the selected year level
  //     const filteredData = Alldata.filter(entry =>
  //       entry.activity_node.some(node => node.level_name === selectedGroup)
  //     )
  //     setMistryTopic(filteredData) // Set the filtered data
  //   }

  //   // Set the selected year level in state
  //   setselectedYearLvel(selectedGroup)
  // }

  const filterData = (
    yearLevel = selectedYearLevelTopic,
    masteryScore = selectedMastryScore
  ) => {
    let filteredData = Alldata

    if (masteryScore) {
      if (masteryScore == "1") {
        filteredData = filteredData.filter(item => item.masteryscore == 0)
      } else if (masteryScore == "2") {
        filteredData = filteredData.filter(
          item => item.masteryscore < 30 && item.masteryScore > 0
        )
      } else if (masteryScore == "3") {
        filteredData = filteredData.filter(
          item => item.masteryscore >= 30 && item.masteryscore <= 69
        )
      } else if (masteryScore == "4") {
        filteredData = filteredData.filter(item => item.masteryscore >= 70)
      }
    }

    if (yearLevel !== "all") {
      filteredData = filteredData.filter(item =>
        item.activity_node.some(node => node.level_name === yearLevel)
      )
    }

    setMistryTopic(filteredData)
  }

  const [selectedMastryScore, setselectedMasterScore] = useState("")
  function handleSelectMastryScore(selectedGroup) {
    //console.log(selectedGroup);
    setselectedMasterScore(selectedGroup)
    filterData(selectedYearLevelTopic, selectedGroup)
  }

  const optionMastryScore = [
    { label: "Select", value: "" },
    { label: "No score", value: "1" },
    { label: "Low Mastery", value: "2" },
    { label: "Medium Mastery", value: "3" },
    { label: "High Mastery", value: "4" },
  ]

  const [isRight, setIsRight] = useState(false)
  const [studentKay, setStudentKey] = useState("")
  const toggleRightCanvas = studentKey => {
    setIsRight(!isRight)
    fetchStudentData(studentKey)
    fetchlessonNotesData(studentKey)
    fetchpastsessionData(studentKey)
    fetchMastryScoresessionData(studentKey)
    setStudentKey(studentKey)
  }

  const [isRight1, setIsRight1] = useState(false)

  const toggleRightCanvas1 = () => {
    setIsRight1(!isRight1)
  }

  const [isRightTopicGraph, setIsRightTopicGraph] = useState(false)

  const toggleRightCanvasTopicGraph = () => {
    setIsRightTopicGraph(!isRightTopicGraph)
    const today = new Date()
    const sixMonthsAgo = new Date()
    sixMonthsAgo.setMonth(today.getMonth() - 6)
    setDateValue([sixMonthsAgo,today])
  }

  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  // const [date, setDate] = useState(new Date());
  const [date, setDate] = useState([null, null])

  const [subject, setSubject] = useState("")
  const [type, setType] = useState("")
  const [status, setStatus] = useState("")
  //const [Alldata, setAllData] = useState([]);
  const [data, setData] = useState([])
  const [records, setRecords] = useState(data)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [startDate, setStartDate] = useState("")
  const handelDateReset = () => {
    setStartDate("")
  }

  const [isTopicId, setTopicId] = useState("")
  const [isNodeId, setNodeId] = useState("")

  const [MistryTopic, setMistryTopic] = useState([])
  const [Alldata, setAllData] = useState([])
  const [topicData, setToipc] = useState([])
  const [mastryScore, setMastryScore] = useState([])
  const [TopicGraphDeatils, setMistryTopicGraphDeatils] = useState([])
  const [TopicGraphDeatilsValueData, setTopicGraphDeatilsValueData] = useState(
    []
  )
  const [TopicGraphDeatilsLabelData, setTopicGraphDeatilsLabelData] = useState(
    []
  )

  //Kathir start
  const [optionGroup, setoptionGroup] = useState([])
  const [levelOption, setLevelOptions] = useState([])
  const [isFilter, setFilter] = useState(false)
  const [isChecked, setChecked] = useState(true)

  const [PastSessionStuData, setPastSessionStuData] = useState([])
  const [LessonNoteDetail, setLessonNoteDetail] = useState([])
  //yogi start
  const [studentDetail, setstudentDetail] = useState([])
  //yogi end
  const handleCheckboxChange = () => {
    setChecked(!isChecked) // Toggle the checkbox state
  }

  const handleButtonClick = rowData => {
    if (rowData.isChecked === 1) {
      navigate(`/session/${rowData.pk_ses_key}`)
    } else {
      toggleRightCanvas(rowData.pk_student_key)
    }
  }
  const navigate = useNavigate()
  //Kathir end
  const authUser = JSON.parse(localStorage.getItem("authUser")) || null

  const fetchData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        if (
          sessionStorage.getItem("UnplanedLesson") &&
          sessionStorage.getItem("UnplanedLesson") == "true"
        ) {
          setChecked(true)
          sessionStorage.removeItem("UnplanedLesson")
        }
        setLoading(true)
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const response = await axios.post(BASE_URL + "student/getAllStudent", {
          draw: 1,
          start: 0,
          length: rowsPerPage,
          order: [{ column: 0, dir: "asc" }],
          columns: [
            { data: "session_date" },
            { data: "session_time" },
            { data: "sest_name" },
            { data: "total_student" },
            { data: "subject" },
            { data: "sest_type" },
            { data: "ses_is_completed" },
          ],
          search: { value: "" },
          startDate: date[0] || null,
          endDate: date[1] || null,

          subject: subject,
          type: type,
          status: status,

          // filterStartDate: startDate,
          filterunplannedLesson: isChecked,
          filterLevel: selectedGroup,
        })
        //console.log(response)
        if (response.data.length > 0) {
          const updatedData = response.data.map(row => ({
            ...row,
            isChecked: isChecked ? 1 : 0,
          }))

          setData(updatedData)

          if (searchValue && searchValue != "") {
            const inputValue = searchValue.trim().toLowerCase()
            const newData = updatedData.filter(row => {
              return (
                (row.pk_student_id && row.pk_student_id != ""
                  ? row.pk_student_id
                  : "-"
                )
                  .toString()
                  .includes(inputValue) ||
                (row.st_first_name && row.st_first_name != ""
                  ? row.st_first_name + " " + row.st_surname
                  : "-"
                )
                  .toLowerCase()
                  .includes(inputValue) ||
                (row.level_name && row.level_name != "" ? row.level_name : "-")
                  .toLowerCase()
                  .includes(inputValue) ||
                (row.fk_sc_id != null || row.fk_sc_id != ""
                  ? row.fk_sc_id
                  : "-"
                )
                  .toLowerCase()
                  .includes(inputValue) ||
                (row.sesion_count != null || row.sesion_count != ""
                  ? row.sesion_count
                  : "-"
                )
                  .toString()
                  .toLowerCase()
                  .includes(inputValue) ||
                (row.next_session != null || row.next_session != ""
                  ? moment(row.next_session, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                      "DD-MM-YYYY"
                    )
                  : "-"
                ).includes(inputValue)
              )
            })
            setRecords(newData)
          } else {
            const updatedData = response.data.map(row => ({
              ...row,
              isChecked: isChecked ? 1 : 0,
            }))
            setRecords(updatedData)
          }
          setLoading(false)
        } else {
          setData([])
          setRecords([])
          // console.log(dataTableData)
          setLoading(false)
        }
      } else {
        navigate("/login")
        setLoading(false)
      }

      // setAllData(response.data);
    } catch (error) {
      setLoading(false)
      console.error("Error fetching data:", error)
      if (
        error.response.status &&
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    }
  }
  //yogi start
  const fetchStudentData = async studentKey => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseStudent = await axios.post(
          BASE_URL + "student/getstudentdetails",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);
        // console.log(responseStudent.data.data.data)
        if (responseStudent.data.data.data != "") {
          setstudentDetail(responseStudent.data.data.data)
        } else {
          setstudentDetail([])
        }
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }
  const fetchlessonNotesData = async studentKey => {
    // console.log(studentKey);
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseStudentNotes = await axios.post(
          BASE_URL + "student/getLessonNotesDetails",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);

        if (responseStudentNotes.data.data.data != "") {
          setLessonNoteDetail(responseStudentNotes.data.data.data)
        } else {
          setLessonNoteDetail([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchpastsessionData = async studentKey => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responsePastSessionStudent = await axios.post(
          BASE_URL + "student/getpastsessionDetails",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responsePastSessionStudent.data.data.data) {
          setPastSessionStuData(responsePastSessionStudent.data.data.data)
        } else {
          setPastSessionStuData([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  const fetchTopicGraphData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastryScroreStudentGraph = await axios.post(
          BASE_URL + "student/TopicGraphDetails",
          {
            pk_student_key: studentKay,
            filter_node_id: isNodeId,
            filter_topic_id: isTopicId,
            filter_date: startDate,
            startDate: moment(dateValue[0]).format("YYYY-MM-DD"),
            endDate: moment(dateValue[1]).format("YYYY-MM-DD"),
          }
        ) //console.log(studentKey);

        //console.log(responseMastryScroreStudentGraph.data)

        if (responseMastryScroreStudentGraph.data.TopicDetails != "") {
          console.log(responseMastryScroreStudentGraph.data[0]?.ses_date)
          setMistryTopicGraphDeatils(
            responseMastryScroreStudentGraph.data.TopicDetails
          )
          setTopicGraphDeatilsLabelData(
            responseMastryScroreStudentGraph.data.TopicDetails.LabelData
          )
          setTopicGraphDeatilsValueData(
            responseMastryScroreStudentGraph.data.TopicDetails.ValueData
          )

          const formattedDate = moment(
            responseMastryScroreStudentGraph.data[0]?.ses_date
          ).format("yyyy-MM")
          console.log(formattedDate)
          //  setStartDate(formattedDate)
        } else {
          setMistryTopicGraphDeatils([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  // console.log(TopicGraphDeatilsValueData);
  useEffect(() => {
    if (isRightTopicGraph) {
      fetchTopicGraphData()
    } else {
    }
  }, [isRightTopicGraph, startDate, dateValue])

  const fetchMastryScoreTopicListData = async () => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastryScroreStudent = await axios.post(
          BASE_URL + "student/mastryScroeTopic",
          {
            pk_student_key: studentKay,
            // filter_year_level: selectedYearLevelTopic,
            // filter_mastery_range: selectedMastryScore,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responseMastryScroreStudent.data.length > 0) {
          const data = responseMastryScroreStudent.data.filter(
            entry => entry.activity_node.length > 0
          )
          //console.log(data)
          const levelNames = [
            ...new Set(
              data
                .flatMap(entry => entry.activity_node)
                .map(node => node.level_name)
            ),
          ]

          setMistryTopic(data)
          setAllData(data)
          setLevelOptions(levelNames)
        } else {
          setMistryTopic([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isRight1) {
      fetchMastryScoreTopicListData()
      setLevelOptions([])
      setselectedMasterScore("")
    } else {
    }
  }, [isRight1])

  const fetchMastryScoresessionData = async studentKey => {
    if (authUser && authUser != "" && authUser != null) {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseMastrySessionStudent = await axios.post(
          BASE_URL + "student/mastryScroe",
          {
            pk_student_key: studentKey,
          }
        ) //console.log(studentKey);

        //console.log(responsePastSessionStudent.data.data.data);

        if (responseMastrySessionStudent.data != "") {
          if (responseMastrySessionStudent.data.studentDetails != "") {
            setMastryScore(responseMastrySessionStudent.data.studentDetails)
          } else {
            setMastryScore([])
          }

          if (responseMastrySessionStudent.data.studentprogressBar != "") {
            setToipc(responseMastrySessionStudent.data.studentprogressBar)
          } else {
            setToipc([])
          }
        } else {
          setMastryScore([])
        }
        //setstudentDetail(responseStudent.data.data.data)
      } catch (error) {
        console.error("Error in fetching stident deatails:", error)
      }
    } else {
      navigate("/login")
      setLoading(false)
    }
  }

  function generateRandomColors(count) {
    const colors = []
    for (let i = 0; i < count; i++) {
      // Generate a random color in hexadecimal format
      const color = "#" + Math.floor(Math.random() * 16777215).toString(16)
      colors.push(color)
    }
    return colors
  }

  function labelName(data_label) {
    const label = []

    if (data_label.mistryScore && data_label.mistryScore.length > 0) {
      data_label.mistryScore.forEach(activity => {
        var labe = ""
        if (
          activity.activity_type === 0 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].node_name != "" &&
            activity.activity_node[0].level_name != ""
              ? activity.activity_node[0].level_name +
                " > " +
                activity.activity_node[0].node_name
              : activity.activity_node &&
                activity.activity_node.length > 0 &&
                activity.activity_node[0].node_name != ""
              ? activity.activity_node[0].node_name
              : ""
        } else if (
          activity.activity_type === 1 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 2 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].topic_name != "" &&
            activity.activity_node[0].level_name != ""
              ? activity.activity_node[0].level_name +
                " > " +
                activity.activity_node[0].topic_name
              : activity.activity_node[0].topic_name != ""
              ? activity.activity_node[0].topic_name
              : null
        } else if (
          activity.activity_type === 3 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 4 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (
          activity.activity_type === 5 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].name != ""
              ? activity.activity_node[0].name
              : null
        } else if (activity.activity_type === 6 && activity.fk_sub_id === 1) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].video_name != ""
              ? activity.activity_node[0].video_name
              : null
        } else if (activity.activity_type === 6 && activity.fk_sub_id === 2) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].video_name != ""
              ? activity.activity_node[0].video_name
              : null
        } else if (
          activity.activity_type === 7 &&
          (activity.fk_sub_id === 1 || activity.fk_sub_id === 2)
        ) {
          labe =
            activity.activity_node &&
            activity.activity_node.length > 0 &&
            activity.activity_node[0].file_name != ""
              ? activity.activity_node[0].file_name
              : null
        } else {
          labe = ""
        }

        label.push(labe)
      })
    }
    return label
  }

  function labelValue(data_label) {
    const labelValue = []

    if (data_label.mistryScore && data_label.mistryScore.length > 0) {
      data_label.mistryScore.forEach(activity => {
        var val =
          activity.masteryscore != "" && activity.masteryscore != ""
            ? activity.masteryscore
            : null
        labelValue.push(val)
      })
    }
    return labelValue
  }

  const options_pie_mastry = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
      },
    },

    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  }

  const pie_mastry_data = {
    labels: labelName(mastryScore),
    datasets: [
      {
        data: labelValue(mastryScore),
        //backgroundColor: generateRandomColors(50),
        backgroundColor: [
          "#FF5733",
          "#FFD700",
          "#FF6347",
          "#FF8C00",
          "#FF4500",
          "#FF1493",
          "#FF00FF",
          "#FF00FF",
          "#FF0000",
          "#FF69B4",
          "#FF7F50",
          "#FF8C00",
          "#FF4500",
          "#FF6347",
          "#FFD700",
          "#FF5733",
          "#FF69B4",
          "#FF1493",
          "#FF00FF",
          "#FF00FF",
          "#FF0000",
          "#FF7F50",
          "#FF8C00",
          "#FF4500",
          "#FF6347",
        ],

        hoverBorderColor: "#fff",
      },
    ],
  }

  //yogi end

  const fetchMasterData = async () => {
    try {
      if (authUser && authUser != "" && authUser != null) {
        // setLoading(true);
        axios.defaults.headers.common["Authorization"] = `Bearer ${authUser}`
        const responseLevel = await axios.post(BASE_URL + "student/yearLevel")
        //console.log(response)
        if (responseLevel.data.length > 0) {
          const formattedyearOptions = responseLevel.data.map(option => ({
            value: option.activity_level_id,
            label: option.level_name,
          }))
          formattedyearOptions.unshift({
            value: "",
            label: "Select",
          })
          setoptionGroup(formattedyearOptions)
        } else {
          setoptionGroup([])
        }
      } else {
        navigate("/login")
      }
    } catch (error) {
      if (
        error.response.status === 403 &&
        error.response.data.error === "jwt expired"
      ) {
        localStorage.clear()
        navigate("/login")
      }
    }
  }

  const clearFilters = () => {
    // setDate(new Date());
    setDate([null, null])
    setSubject("")
    setType("")
    setStatus("")
    fetchData()
  }

  const filterApply = () => {
    toggleRightCanvas()
    setFilter(!isFilter)
  }

  const filterReset = () => {
    handleSelectGroup("")
    setsearchValue("")
    setChecked(false)
    setFilter(!isFilter)
  }

  useEffect(() => {
    fetchData()
    fetchMasterData()
  }, [])

  useEffect(() => {
    fetchData()
  }, [isChecked, selectedGroup])
  //datable

  // useEffect(() => {
  //   fetchData()
  // }, [])

  // const products = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     level: "Intermediate",
  //     school: "ABC School",
  //     sessions: 10,
  //     attended: 7,
  //     nextsession: "2024-02-10",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     level: "Advanced",
  //     school: "XYZ School",
  //     sessions: 15,
  //     attended: 12,
  //     nextsession: "2024-02-12",
  //   },
  //   {
  //     id: 3,
  //     name: "Michael Johnson",
  //     level: "Beginner",
  //     school: "123 School",
  //     sessions: 8,
  //     attended: 6,
  //     nextsession: "2024-02-08",
  //   },
  //   {
  //     id: 4,
  //     name: "Emily Brown",
  //     level: "Intermediate",
  //     school: "456 School",
  //     sessions: 12,
  //     attended: 10,
  //     nextsession: "2024-02-11",
  //   },
  //   // Add more product data as needed
  // ]

  const columns = useMemo(
    () => [
      {
        name: "Student ID",
        Header: "Student ID",
        accessor: row =>
          row.pk_student_id && row.pk_student_id != ""
            ? row.pk_student_id
            : "-",
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "Student Name",
        Header: "Student Name",
        // accessor: 'st_first_name',
        accessor: row =>
          row.st_first_name && row.st_first_name != ""
            ? row.st_first_name + " " + row.st_surname
            : "-",
      },
      {
        name: "Level",
        Header: "Level",
        accessor: row =>
          row.level_name && row.level_name != "" ? row.level_name : "-",
      },
      {
        name: "School Name",
        Header: "School Name",
        accessor: row =>
          row.fk_sc_id != null && row.fk_sc_id != "" ? row.fk_sc_id : "-",
      },
      {
        name: "Total Session",
        Header: "Total Session",
        accessor: row =>
          row.sesion_count != null && row.sesion_count != ""
            ? row.sesion_count
            : "-",
      },
      {
        name: "Next Session",
        Header: "Next Session",
        accessor: row =>
          row.next_session != null && row.next_session != ""
            ? moment(row.next_session, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              )
            : "-",
      },
      //  {
      //       Header: "Status",
      //       disableSortBy: true,
      //       accessor: 'pk_student_key',
      //       disableFilters: true,

      //       Cell: ({ row }) => (<><button className='btn status-btn'> ON GOING</button></>),

      // },
      {
        Header: " ",
        disableSortBy: true,
        accessor: "pk_student_key",
        disableFilters: true,

        Cell: ({ row }) => (
          <>
            <button
              className="btn p-0 hgt"
              // onClick={() => toggleRightCanvas(row.original.pk_student_key)}
              onClick={() => {
                console.log(isChecked) // Log the checked state
                handleButtonClick(row.original)
              }}
            >
              <i
                class="mdi mdi-chevron-right-circle-outline"
                style={{ color: "#979797", fontSize: "24px" }}
              ></i>
            </button>
          </>
        ),
      },
      {
        accessor: "student_key",
        disableSortBy: true,
        disableFilters: true,
        isVisible: false,

        selector: row => row.values.pk_student_key,
        show: false,
      },
    ],
    []
  )

  const columns1 = useMemo(
    () => [
      {
        name: "Date",
        Header: "Date",
        accessor: row =>
          row.ses_date != null || row.ses_date != ""
            ? moment(row.ses_date, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
                "DD-MM-YYYY"
              )
            : "-",
        // disableFilters: true,
        // disableSortBy: true,
      },
      {
        name: "Time",
        Header: "Time",
        accessor: row =>
          row.ses_start_time != null || row.ses_start_time != ""
            ? moment(row.ses_start_time, "HH:mm:ss").format("hh:mm A") +
              " " +
              moment(row.ses_end_time, "HH:mm:ss").format("hh:mm A")
            : "-",
      },
      {
        name: "Sestion Name",
        Header: "Sestion Name",
        accessor: row =>
          row.sest_name != null || row.sest_name != "" ? row.sest_name : "-",
      },
      {
        name: "Subject",
        Header: "Subject",
        accessor: row =>
          row.student_subject == null || row.student_subject == ""
            ? "-"
            : row.student_subject,
      },
      {
        name: "Type",
        Header: "Type",
        accessor: row =>
          row.sest_is_holidays == null || row.sest_is_holidays == ""
            ? "-"
            : row.sest_is_holidays == "1"
            ? "Recurring"
            : row.sest_is_holidays == "2"
            ? "One Off Session"
            : row.sest_is_holidays == "3"
            ? "Make Up Session"
            : "-",
      },
      {
        Header: "Status",
        disableSortBy: true,
        accessor: "ses_status",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.original
          console.log(rowValue.ses_status)
          // Conditionally render content based on the value of tp_status
          if (parseInt(rowValue.ses_status) === 0) {
            return (
              <button className="btn tb-primary text-white tb-bdge">
                Upcoming
              </button>
            )
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 1
          ) {
            return <button className="btn tb-success tb-bdge">Completed</button>
          } else if (
            parseInt(rowValue.ses_status) === 1 &&
            parseInt(rowValue.ses_is_completed) === 0
          ) {
            return (
              <button className="btn tb-warning text-white tb-bdge">
                {" "}
                On Going
              </button>
            )
          } else {
            return (
              <button className="btn tb-primary text-white tb-bdge">
                Upcoming
              </button>
            )
          }
        },
      },
      {
        Header: " ",
        disableSortBy: true,
        accessor: "pk_ses_key",
        disableFilters: true,
        Cell: ({ row }) => {
          const rowValue = row.values
          // Conditionally render content based on the value of tp_status
          if (rowValue.pk_ses_key && rowValue.pk_ses_key != null) {
            return (
              <Link to={"/session/" + rowValue.pk_ses_key}>
                <i
                  class="mdi mdi-chevron-right-circle-outline"
                  style={{ color: "#979797", fontSize: "20px" }}
                ></i>
              </Link>
            )
          } else {
            return ""
          }
        },
      },
      // {
      //   accessor: 'student_key', disableSortBy: true, disableFilters: true,           isVisible: false,

      //   selector: (row) => row.values.pk_student_key, show: false,
      // },
      // {
      //   accessor: 'visibility', disableSortBy: true, disableFilters: true,     isVisible: false,

      //   selector: (row) => row.visibility,

      // }
    ],
    []
  )

  // const paginationOptions = {
  //   paginationSize: 5,
  //   pageStartIndex: 1,
  //   firstPageText: "First",
  //   prePageText: "Back",
  //   nextPageText: "Next",
  //   lastPageText: "Last",
  //   nextPageTitle: "First page",
  //   prePageTitle: "Pre page",
  //   firstPageTitle: "Next page",
  //   lastPageTitle: "Last page",
  // }

  //tab

  // const [activeTab, setActiveTab] = useState("1");

  // const toggleTab = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  function handleFilter(event) {
    setsearchValue(event.target.value)
    const inputValue = event.target.value.trim().toLowerCase()
    const newData = data.filter(row => {
      return (
        (row.pk_student_id && row.pk_student_id != "" ? row.pk_student_id : "-")
          .toString()
          .includes(inputValue) ||
        (row.st_first_name && row.st_first_name != ""
          ? row.st_first_name + " " + row.st_surname
          : "-"
        )
          .toLowerCase()
          .includes(inputValue) ||
        (row.level_name && row.level_name != "" ? row.level_name : "-")
          .toLowerCase()
          .includes(inputValue) ||
        (row.fk_sc_id != null || row.fk_sc_id != "" ? row.fk_sc_id : "-")
          .toLowerCase()
          .includes(inputValue) ||
        (row.sesion_count != null || row.sesion_count != ""
          ? row.sesion_count
          : "-"
        )
          .toString()
          .toLowerCase()
          .includes(inputValue) ||
        (row.next_session != null || row.next_session != ""
          ? moment(row.next_session, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
              "DD-MM-YYYY"
            )
          : "-"
        ).includes(inputValue)
      )
    })
    setRecords(newData)
  }

  const [activeTab1, setActiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setActiveTab1(tab)
    }
  }

  //modal
  const [modal_center, setmodal_center] = useState(false)
  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [modal_center1, setmodal_center1] = useState(false)
  const tog_center1 = () => {
    setmodal_center1(!modal_center1)
    removeBodyCss()
  }

  // Function to handle date change
  const handleDateChange = date => {
    // setStartDate(date)
    setDateValue(date)

    // Here you can implement logic to fetch data for the selected month
    // and update the chart accordingly
  }

  // // Function to generate labels and data for the last six months
  // const generateLastSixMonthsData = () => {
  //   const labels = [];
  //   const dataPoints = [];
  //   let currentDate = new Date();
  //   for (let i = 0; i < 6; i++) {

  //     console.log(currentDate.getMonth());
  //     console.log(currentDate.getMonth());
  //     labels.unshift(currentDate.toLocaleString('default', { month: 'long' }));
  //     // Replace this with actual data-fetching logic based on the current month
  //     dataPoints.unshift(Math.floor(Math.random() * 100)); // Sample random data
  //     currentDate.setMonth(currentDate.getMonth() - 1);
  //   }
  //   return { labels, dataPoints };
  // };

  // const { labels, dataPoints } = generateLastSixMonthsData();

  // console.log(labels);

  // console.log(TopicGraphDeatils.LabelData);

  const data_area_chart = {
    labels: TopicGraphDeatilsLabelData || [],
    datasets: [
      {
        label: "Mastery score over time",
        data: TopicGraphDeatilsValueData || [],
        fill: true,
        backgroundColor: "rgba(192,192,192,0.2)", // Gray color with opacity
        borderColor: "rgba(192,192,192,1)", // Gray color
      },
    ],
  }

  return (
    <div className="page-content">
      <Loader
        show={loading}
        type="body"
        // stack="vertical"
        // message="Loading Data"
      />
      <Container fluid>
        <div className="page-title-box">
          <div className="custom-pg_header  flex-wrap gap-2 mb-2  d-flex w-100">
            <div className="d-flex gap-2 align-items-center w-100">
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className="short-ic"
                    onClick={() => window.history.back()}
                  >
                    <img className="bc-bg2 mr-30" src={chevleft} />
                  </div>

                  <div>
                    <h6
                      className="page-title inner-pg-title mb-0"
                      style={{ width: "17vw" }}
                    >
                      {" "}
                      Students{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <form className="app-search page-search w-60">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for student ID, name..."
                    value={searchValue || ""}
                    onChange={handleFilter}
                  />
                  <span className="ion ion-ios-search"></span>
                </div>
              </form>

              <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={toggleRightCanvas}
              >
                <OffcanvasHeader
                  toggle={toggleRightCanvas}
                  className="border-bottom off-can-header align-items-start"
                >
                  <div className="stu-detail-wrap ">
                    <div className="d-flex">
                      <div className="me-10 mt-5">
                        <img width={40} src={st1} alt="" />
                      </div>
                      <div className="">
                        <label className="student_label m-0">
                          {studentDetail &&
                          studentDetail.st_first_name &&
                          studentDetail.st_first_name != ""
                            ? studentDetail.st_first_name.concat(
                                " " +
                                  (studentDetail.st_surname
                                    ? studentDetail.st_surname
                                    : "")
                              )
                            : null}
                        </label>
                        <div className="">
                          <label className="student_label2 font-size-18 m-0 d-flex align-items-center">
                            {studentDetail &&
                            studentDetail.activity_level &&
                            studentDetail.activity_level.level_name &&
                            studentDetail.activity_level.level_name !== ""
                              ? studentDetail.activity_level.level_name
                              : null}{" "}
                            <i className="fa fa-circle ms-1"> </i>{" "}
                            {studentDetail &&
                            studentDetail.tbl_school &&
                            studentDetail.tbl_school.sc_name &&
                            studentDetail.tbl_school.sc_name !== ""
                              ? studentDetail.tbl_school.sc_name
                              : null}
                          </label>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="student_label_id me-1 font-size-12">
                            Student ID{" "}
                            {studentDetail &&
                            studentDetail.pk_student_id &&
                            studentDetail.pk_student_id != ""
                              ? studentDetail.pk_student_id
                              : null}
                          </p>
                          <i className="fa fa-circle text-dark me-1"></i>
                          <p className="student_label_id font-size-12">
                            Joined{" "}
                            {studentDetail &&
                            studentDetail.enroll_Date &&
                            studentDetail.enroll_Date != ""
                              ? moment(
                                  studentDetail.enroll_Date,
                                  "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                ).format("DD MMM YYYY")
                              : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </OffcanvasHeader>
                <OffcanvasBody className="cstm-off-body">
                  <div className="white-contrast">
                    <div>
                      <label className="student_label_id">Next Session</label>
                    </div>
                    {studentDetail &&
                    studentDetail.next_session &&
                    studentDetail.next_session.ses_date != "" ? (
                      <>
                        <div className="d-flex align-items-start">
                          <div className="stud_date">
                            <label className="font-size-16 fw-700">
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.ses_date != ""
                                ? moment(
                                    studentDetail.next_session.ses_date,
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                  ).format("DD")
                                : null}
                            </label>

                            <label className="student_label_id font-size-16">
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.ses_date != ""
                                ? moment(
                                    studentDetail.next_session.ses_date,
                                    "YYYY-MM-DDTHH:mm:ss.SSSZ"
                                  ).format("MMM")
                                : null}
                            </label>
                          </div>

                          <div class="d-flex flex-column">
                            <label className="student_label_id m-0 font-size-15">
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.ses_start_time &&
                              studentDetail.next_session.ses_end_time
                                ? (() => {
                                    const startMoment = moment(
                                      studentDetail.next_session.ses_start_time,
                                      "HH:mm"
                                    )
                                    const endMoment = moment(
                                      studentDetail.next_session.ses_end_time,
                                      "HH:mm"
                                    )
                                    const durationMinutes = endMoment.diff(
                                      startMoment,
                                      "minutes"
                                    )
                                    const formattedDuration = `(${durationMinutes} min)`
                                    return (
                                      startMoment.format("hh:mm A") +
                                      " - " +
                                      endMoment.format("hh:mm A") +
                                      " " +
                                      formattedDuration
                                    )
                                  })()
                                : null}
                            </label>

                            <label className="m-0 fw-800 font-size-15">
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.tbl_session_time &&
                              studentDetail.next_session.tbl_session_time
                                .sest_name != ""
                                ? studentDetail.next_session.tbl_session_time
                                    .sest_name
                                : null}
                            </label>

                            <label className="student_label_id m-0">
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.tbl_session_time &&
                              studentDetail.next_session.tbl_session_time
                                .sest_is_holidays != ""
                                ? studentDetail.next_session.tbl_session_time
                                    .sest_is_holidays == null ||
                                  studentDetail.next_session.tbl_session_time
                                    .sest_is_holidays == ""
                                  ? "-"
                                  : studentDetail.next_session.tbl_session_time
                                      .sest_is_holidays == "1"
                                  ? "Recurring"
                                  : studentDetail.next_session.tbl_session_time
                                      .sest_is_holidays == "2"
                                  ? "One Off Session"
                                  : studentDetail.next_session.tbl_session_time
                                      .sest_is_holidays == "3"
                                  ? "Make Up Session"
                                  : "-"
                                : null}{" "}
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.tbl_session_time &&
                              studentDetail.next_session.tbl_session_time
                                .tbl_student_enrolment &&
                              studentDetail.next_session.tbl_session_time
                                .tbl_student_enrolment.tbl_subject_enrolment &&
                              studentDetail.next_session.tbl_session_time
                                .tbl_student_enrolment.tbl_subject_enrolment
                                .sub_name &&
                              studentDetail.next_session.tbl_session_time
                                .tbl_student_enrolment.tbl_subject_enrolment
                                .sub_name != ""
                                ? studentDetail.next_session.tbl_session_time
                                    .tbl_student_enrolment.tbl_subject_enrolment
                                    .sub_name
                                : null}{" "}
                              {studentDetail &&
                              studentDetail.next_session &&
                              studentDetail.next_session.total_student &&
                              studentDetail.next_session.total_student != ""
                                ? `${studentDetail.next_session.total_student}-students`
                                : null}
                            </label>
                          </div>
                        </div>
                        <label
                          className={`${
                            studentDetail &&
                            studentDetail.next_session &&
                            studentDetail.next_session.activity_count &&
                            studentDetail.next_session.activity_count !== ""
                              ? ""
                              : "cs-txt-danger font-size-12 mt-5 ms-0 mb-0"
                          }`}
                        >
                          {studentDetail &&
                          studentDetail.next_session &&
                          studentDetail.next_session.activity_count &&
                          studentDetail.next_session.activity_count != ""
                            ? `${studentDetail.next_session.activity_count} activities assigned`
                            : "No activities assigned"}
                        </label>
                      </>
                    ) : (
                      <p className="ml-3">No next session</p>
                    )}
                  </div>
                  <div className="lesson-wrapper">
                    <div className="bg-white d-flex lesson-div justify-content-between">
                      <h4
                        className="font-size-18 text-dark"
                        style={{ lineHeight: "24px" }}
                      >
                        View past lessons
                      </h4>
                      <button
                        type="button"
                        className="btn p-0"
                        onClick={tog_center}
                      >
                        <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>

                  {/* lesson tab */}

                  <div>
                    <Nav pills className="navtab-bg nav-justified lesson-nav">
                      <NavItem className="lesson-navitem">
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames("lesson-navlink", {
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Notes
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames("lesson-navlink", {
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          Report
                        </NavLink>
                      </NavItem>
                      <NavItem className="tab-btn">
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames("lesson-navlink", {
                            active: activeTab1 === "7",
                          })}
                          onClick={() => {
                            toggle1("7")
                          }}
                        >
                          Contact
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab1 === "8",
              })}
              onClick={() => {
                toggle1("8");
              }}
            >
              Settings
            </NavLink>
          </NavItem> */}
                    </Nav>

                    <TabContent activeTab={activeTab1} className="text-muted">
                      <TabPane tabId="5" className="pd-10">
                        <div className="note-wrap">
                          <div className="">
                            {LessonNoteDetail &&
                            LessonNoteDetail.tbl_lesson_notes &&
                            LessonNoteDetail.tbl_lesson_notes.length > 0 ? (
                              <>
                                {LessonNoteDetail.tbl_lesson_notes.map(
                                  (notes, index) =>
                                    notes.notes_details != null ? (
                                      <span key={index}>
                                        {notes.notes_details}
                                        {index <
                                        LessonNoteDetail.tbl_lesson_notes
                                          .length -
                                          1
                                          ? ", "
                                          : ""}
                                      </span>
                                    ) : null
                                )}
                              </>
                            ) : (
                              <span className="font-size-10 pd-10">
                                No Lesson Notes
                              </span>
                            )}
                          </div>
                          <label className="mt-5 font-size-18 fw-700 mb-0">
                            Other Notes
                          </label>
                          <div className="mt-5 w-100">
                            <p className="bg-white pd-10 font-size-10">
                              {studentDetail &&
                              studentDetail.la_notes &&
                              studentDetail.la_notes &&
                              studentDetail.la_notes !== ""
                                ? studentDetail.la_notes
                                : "No Data"}
                            </p>
                          </div>
                          <label className="mt-5 font-size-18 fw-700 mb-0">
                            Goals
                          </label>
                          <div className="mt-5 ">
                            <p className="bg-white bg-white pd-10 w-100 font-size-10">
                              {studentDetail &&
                              studentDetail.la_goals &&
                              studentDetail.la_goals &&
                              studentDetail.la_goals !== ""
                                ? studentDetail.la_goals
                                : "No Data"}
                            </p>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="6" className="bg-white">
                        <div className="report-wrap pd-10">
                          <div>
                            <label className="student_label2 font-size-18 fw-700">
                              {mastryScore &&
                              mastryScore.level_name &&
                              mastryScore.level_name != ""
                                ? mastryScore.level_name
                                : ""}{" "}
                              <i className="fa fa-circle"></i>{" "}
                              {mastryScore &&
                              mastryScore.subject_name &&
                              mastryScore.subject_name != ""
                                ? mastryScore.subject_name
                                : ""}
                              Curriculum
                            </label>
                          </div>
                          <div className=""></div>
                          <div class="studentdetails-report">
                            <div class="studentdetails-report-values">
                              <label>Classes Attended</label>
                              <span>
                                {mastryScore &&
                                mastryScore.attended_session &&
                                mastryScore.attended_session != ""
                                  ? mastryScore.attended_session
                                  : 0}
                              </span>
                            </div>
                            <div class="studentdetails-report-values">
                              <label>Activities Completed</label>
                              <span>
                                {mastryScore &&
                                mastryScore.attended_session &&
                                mastryScore.attended_session != ""
                                  ? mastryScore.attended_session
                                  : 0}
                              </span>
                            </div>
                          </div>

                          <div className="mt-2">
                            <label className="student_label2 font-size-18 fw-700">
                              Topic mastery{" "}
                              <span onClick={tog_center1}>
                                <i className="font-size-14 far fa-question-circle"></i>
                              </span>
                              <Modal
                                className="mastery-modal"
                                isOpen={modal_center1}
                                toggle={tog_center1}
                                centered
                              >
                                <ModalHeader
                                  className="mt-0 text-dark"
                                  toggle={tog_center1}
                                >
                                  Topic mastery
                                </ModalHeader>
                                <ModalBody className="mastery-modal_body">
                                  <p style={{ marginBottom: "1rem" }}>
                                    A mastery score is an integer from 0 - 100
                                    that indicates a student's competence in a
                                    particular topic at a particular year level.
                                    A mastery score is always given with a (Year
                                    Level) and a (Topic). This year level and
                                    topic is obtained from the Mastery Tree in
                                    LCMS.
                                  </p>
                                  <div
                                    className="good-wrapper"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <p className="state-good fw-700">
                                      High mastery
                                    </p>
                                    <p className="state-good ">
                                      Mastery score of more than 70
                                    </p>
                                    <p>
                                      Your child is proficient at this topic and
                                      can move on to new topics.
                                    </p>
                                  </div>
                                  <div
                                    className="warning-wrapper"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <p className="state-warning fw-700">
                                      Mid mastery
                                    </p>
                                    <p className="state-warning ">
                                      Mastery score of 30 - 69
                                    </p>
                                    <p>
                                      Your child is getting the hang of these
                                      topics and should practice more to get
                                      better at it!
                                    </p>
                                  </div>
                                  <div
                                    className="critical-wrapper"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <p className="state-critical fw-700">
                                      Low mastery
                                    </p>
                                    <p
                                      className="state-critical"
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      Mastery score of less than 30
                                    </p>
                                    <p>
                                      Your child might be new to the topic and
                                      would need time to grasp the fundamental
                                      concepts.!
                                    </p>
                                  </div>
                                  <div
                                    className="disabled-wrapper"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <p className="state-secondary fw-700">
                                      No score
                                    </p>
                                    <p>
                                      Your child will need to complete more
                                      questions in that topic to generate a
                                      mastery score.
                                    </p>
                                  </div>
                                  <div
                                    className="child-wrapper"
                                    style={{ marginBottom: "1rem" }}
                                  >
                                    <p className="state-secondary fw-700 text-dark">
                                      How is the mastery score calculated?{" "}
                                    </p>
                                    <p>
                                      Formula: (No of Q correct on 1st try) /
                                      (Total no of Q in Year Level + Topic) *
                                      100%
                                    </p>
                                  </div>
                                  <p style={{ marginBottom: "1rem" }}>
                                    Rounding: To nearest whole integer
                                  </p>
                                </ModalBody>
                                <ModalFooter></ModalFooter>
                              </Modal>
                            </label>
                          </div>

                          <p className="d-flex flex-column text-center font-size-15 mt-5">
                            No of Topics
                            <span className="font-size-15 fw-700 text-dark">
                              {topicData &&
                              topicData.total_level &&
                              topicData.total_level != ""
                                ? topicData.total_level
                                : 0}
                            </span>
                          </p>

                          <div className="mt-5">
                            <Progress multi>
                              <Progress
                                bar
                                className="high_mastery"
                                value={25}
                              />

                              <Progress
                                bar
                                className="medium_mastery"
                                value={25}
                              />

                              <Progress
                                bar
                                className="low_mastery"
                                value={25}
                              />
                              <Progress bar className="noscore" value={25} />
                            </Progress>

                            <div className="row">
                              <div className="col-3 text-center">
                                <label
                                  className="topic_mas_label mt-1 font-size-15"
                                  style={{ color: "#63d3a6" }}
                                >
                                  High mastery
                                </label>
                                <p className="topic_mas_para">
                                  <b className="font-size-15">
                                    {topicData &&
                                    topicData.high_level &&
                                    topicData.high_level != ""
                                      ? topicData.high_level
                                      : 0}
                                  </b>{" "}
                                  topic
                                </p>
                              </div>
                              <div className="col-3 text-center">
                                <label
                                  className="topic_mas_label mt-1 font-size-15"
                                  style={{ color: "#f6911e" }}
                                >
                                  Medium mastery
                                </label>
                                <p className="topic_mas_para">
                                  <b className="font-size-15">
                                    {topicData &&
                                    topicData.mid_level &&
                                    topicData.mid_level != ""
                                      ? topicData.mid_level
                                      : 0}
                                  </b>{" "}
                                  topic
                                </p>
                              </div>
                              <div className="col-3 text-center">
                                <label
                                  className="topic_mas_label mt-1 font-size-15"
                                  style={{ color: "#fb0000" }}
                                >
                                  Low mastery
                                </label>
                                <p className="topic_mas_para">
                                  <b className="font-size-15">
                                    {topicData &&
                                    topicData.low_level &&
                                    topicData.low_level != ""
                                      ? topicData.low_level
                                      : 0}
                                  </b>{" "}
                                  topic
                                </p>
                              </div>
                              <div className="col-3 text-center">
                                <label
                                  className="topic_mas_label mt-1 font-size-15"
                                  style={{ color: "#b3b3b3" }}
                                >
                                  No score
                                </label>
                                <p className="topic_mas_para">
                                  <b className="font-size-15">
                                    {topicData &&
                                    topicData.no_score_level &&
                                    topicData.no_score_level != ""
                                      ? topicData.no_score_level
                                      : 0}
                                  </b>{" "}
                                  topic
                                </p>
                              </div>
                            </div>

                            <button
                              className="btn report-btn"
                              onClick={toggleRightCanvas1}
                            >
                              All topics <i className="fas fa-arrow-right"></i>{" "}
                            </button>
                            {mastryScore &&
                            mastryScore.mistryScore &&
                            mastryScore.mistryScore.length > 0 ? (
                              <>
                                {" "}
                                <h5 className="mt-1 font-size-18 text-dark">
                                  Topics practiced in the past month
                                </h5>
                                <div className="justify-content-right h-30 w-98">
                                  <Pie
                                    width={98}
                                    height={30}
                                    data={pie_mastry_data}
                                    options={options_pie_mastry}
                                    style={{
                                      height: "270px",
                                      width: "560px",
                                    }}
                                  />
                                </div>
                                <div>
                                  <h5 className="font-size-18 text-dark">
                                    Improve their mastery score
                                  </h5>
                                  <p className="mb-1 font-size-15 text-dark">
                                    Practice these topics more
                                  </p>
                                  <div className="justify-content-between d-flex">
                                    <p className="text-uppercase font-size-12 fw-700 luminous-wht mb-2">
                                      TOPIC
                                    </p>
                                    <p className="text-uppercase font-size-12 fw-700 luminous-wht mt-0 mb-2">
                                      MASTERY SCORE
                                    </p>
                                  </div>
                                  {mastryScore.mistryScore.map(activity => {
                                    if (
                                      activity.activity_type === 0 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div className="font-size-15 text-dark w-70 mb-1">
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0]
                                              .node_name != ""
                                              ? activity.activity_node[0]
                                                  .node_name
                                              : null}
                                          </div>
                                          <div
                                            className={`font-size-15 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 1 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 2 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0]
                                              .topic_name != ""
                                              ? activity.activity_node[0]
                                                  .topic_name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 3 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 4 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 5 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0].name != ""
                                              ? activity.activity_node[0].name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 6 &&
                                      activity.fk_sub_id === 1
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0]
                                              .video_name != ""
                                              ? activity.activity_node[0]
                                                  .video_name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 6 &&
                                      activity.fk_sub_id === 2
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0]
                                              .video_name != ""
                                              ? activity.activity_node[0]
                                                  .video_name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else if (
                                      activity.activity_type === 7 &&
                                      (activity.fk_sub_id === 1 ||
                                        activity.fk_sub_id === 2)
                                    ) {
                                      return (
                                        <div className="justify-content-between d-flex">
                                          <div>
                                            {activity.activity_node &&
                                            activity.activity_node.length > 0 &&
                                            activity.activity_node[0]
                                              .file_name != ""
                                              ? activity.activity_node[0]
                                                  .file_name
                                              : null}
                                          </div>
                                          <div
                                            className={`ms-3 ${
                                              activity.masteryscore >= 70
                                                ? "text-success"
                                                : activity.masteryscore >= 30 &&
                                                  activity.masteryscore <= 69
                                                ? "text-warning"
                                                : activity.masteryscore <= 30 &&
                                                  activity.masteryscore >= 1
                                                ? "text-danger"
                                                : ""
                                            }`}
                                          >
                                            {activity.masteryscore != "" &&
                                            activity.masteryscore != ""
                                              ? activity.masteryscore
                                              : "No Score"}
                                            <span>
                                              <i
                                                className="mdi mdi-chevron-right"
                                                onClick={() => {
                                                  toggleRightCanvasTopicGraph()
                                                  setTopicId(
                                                    activity.ms_topic_id
                                                  )
                                                  setNodeId(
                                                    activity.fk_activity_node_id
                                                  )
                                                }}
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      )
                                    } else {
                                      return null // or any other JSX you want to render for other activity types
                                    }
                                  })}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="7">
                        <div class="contact-wrap">
                          <div class="contact-stu-det mb-10">
                            <label className="mb-10 font-size-18 text-dark">
                              Student Details
                            </label>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Gender Identity
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.st_gender &&
                                studentDetail.st_gender &&
                                studentDetail.st_gender !== ""
                                  ? studentDetail.st_gender
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Date of birth
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.st_dob &&
                                studentDetail.st_dob !== ""
                                  ? moment(
                                      studentDetail.st_dob,
                                      moment.ISO_8601
                                    ).format("DD MMM YYYY")
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                School
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.fk_sc_id &&
                                studentDetail.fk_sc_id &&
                                studentDetail.fk_sc_id !== ""
                                  ? studentDetail.fk_sc_id
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Email
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.st_email &&
                                studentDetail.st_email &&
                                studentDetail.st_email !== ""
                                  ? studentDetail.st_email
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Mobile
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.st_phone &&
                                studentDetail.st_phone !== ""
                                  ? "+" + studentDetail.st_phone
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Country
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.country_name &&
                                studentDetail.country_name &&
                                studentDetail.country_name !== ""
                                  ? studentDetail.country_name
                                  : null}
                              </span>
                            </div>
                          </div>
                          <div class="contact-stu-det">
                            <label className=" mb-10 font-size-18 text-dark">
                              Contact Details
                            </label>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                First name
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_firstname &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_firstname !== ""
                                  ? studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_firstname
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Last name
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_surname &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_surname !== ""
                                  ? studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_surname
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Relationship
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation
                                  .ps_relationship !== ""
                                  ? studentDetail.tbl_parstu_relation
                                      .ps_relationship
                                  : null}
                              </span>
                            </div>
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Mobile
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone !== ""
                                  ? "+" +
                                    studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_phone
                                  : null}
                              </span>
                            </div>
                            {/* <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Work
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_phone !== ""
                                  ? studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_phone
                                  : null}
                              </span>
                            </div> */}
                            <div class="contact-stu-value">
                              <label className="font-size-15 fw-400">
                                Email
                              </label>
                              <span className="font-size-15 fw-700">
                                {studentDetail &&
                                studentDetail.tbl_parstu_relation &&
                                studentDetail.tbl_parstu_relation.tbl_parent &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_emailid &&
                                studentDetail.tbl_parstu_relation.tbl_parent
                                  .par_emailid !== ""
                                  ? studentDetail.tbl_parstu_relation.tbl_parent
                                      .par_emailid
                                  : null}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      {/* <TabPane tabId="8">
            <Row>
              <Col sm="12">
                <p className="mb-0">
                  Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater. Lomo wolf viral, mustache readymade thundercats keffiyeh craft beer marfa ethical. Wolf salvia freegan, sartorial keffiyeh echo park vegan.
                </p>
              </Col>
            </Row>
          </TabPane> */}
                    </TabContent>
                  </div>
                </OffcanvasBody>
              </Offcanvas>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 mb-3">
          <div className="filter-child me-3">
            <label>Level</label>
            <Select
              className="stud_level"
              value={optionGroup.find(option => option.value == selectedGroup)}
              onChange={selectedOption => {
                handleSelectGroup(selectedOption["value"])
              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </div>

          <div className="form-check mt-4 ms-3 d-flex">
            <input
              type="checkbox"
              className="form-check-input check_box"
              id="unplannedLesson"
              checked={isChecked} // Control the checkbox state
              onChange={handleCheckboxChange}
            />

            <label className="form-check-label mb-0" htmlFor="unplannedLesson">
              Unplanned lessons (next 7 days)
            </label>
          </div>
          <div className="col-3  mt-4 ms-3">
            <button
              type="button"
              class="btn btn btn-primary filter-btn mt-0"
              onClick={filterReset}
            >
              Clear Filter
            </button>
          </div>
        </div>
        <div className="student_table">
          <TableComm
            loading={loading}
            columns={columns}
            defaultId={"pk_student_id"}
            data={records}

            //onRowClick={handleStudentViewClick}
            //hiddenColumns={['visibility', 'student_key']}
          />
          {/* <BootstrapTable
            keyField="id"
            data={products}
            columns={columns}
            pagination={paginationFactory(paginationOptions)}
            filter={filterFactory()}
            // cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
            //striped
            // hover
            // condensed
            loading={loading}
            noDataIndication={() => "No data found"}
          /> */}
        </div>

        <Offcanvas
          isOpen={isRight1}
          direction="end"
          toggle={toggleRightCanvas1}
        >
          <OffcanvasHeader
            toggle={toggleRightCanvas1}
            className="border-bottom off-can-header cp-2"
          >
            <div
              className="d-flex align-items-center"
              onClick={toggleRightCanvas1}
            >
              <div className="short-ic">
                <img className="bc-bg" src={chevleft} />
              </div>
              <p className="student_label_id font-size-12 ">Back</p>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <div className="alltopic-wrap">
              <div className="white-contrast p-0">
                <div
                  className=""
                  style={{ padding: "10px", paddingBottom: "0" }}
                >
                  <label className="student_label2 font-size-12 fw-700 m-0">
                    View all topics
                  </label>
                </div>
                <div className="d-flex pd-10 align-items-center">
                  <div className="me-10">
                    <img src={sort} />
                  </div>
                  <div>
                    <Select
                      className="stud_level cp-wd me-20"
                      value={levelOption.find(
                        option => option.value == selectedYearLevelTopic
                      )}
                      onChange={selectedOption => {
                        handleSelectYearLevelTopic(selectedOption.value)
                      }}
                      options={[
                        { label: "All", value: "all" },
                        ...levelOption.map(level => ({
                          label: level,
                          value: level,
                        })),
                      ]}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div>
                    <Select
                      className="masterScore_level cp-wd"
                      value={optionMastryScore.find(
                        option => option.value == selectedMastryScore
                      )}
                      onChange={selectedOption => {
                        handleSelectMastryScore(selectedOption["value"])
                      }}
                      options={optionMastryScore}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </div>
              </div>

              {MistryTopic.length > 0 ? (
                MistryTopic.map(activity => {
                  if (
                    activity.activity_type === 0 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid #b5c4d7" }}
                      >
                        <div className="inner-pd pd-10">
                          <div className="justify-content-between d-flex align-items-center">
                            <div>
                              <h5
                                className="font-size-18 fw-700 text-dark"
                                style={{ lineHeight: "24px" }}
                              >
                                {activity.activity_node &&
                                activity.activity_node.length > 0 &&
                                activity.activity_node[0].node_name != ""
                                  ? activity.activity_node[0].node_name
                                  : null}
                              </h5>
                              <p className="mb-0 font-size-15 fw-700 text-dark">
                                {activity.activity_node &&
                                activity.activity_node.length > 0 &&
                                activity.activity_node[0].level_name &&
                                activity.activity_node[0].level_name != ""
                                  ? activity.activity_node[0].level_name
                                  : null}
                              </p>{" "}
                            </div>
                            <strong
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                toggleRightCanvasTopicGraph()
                                setTopicId(activity.ms_topic_id)
                                setNodeId(activity.fk_activity_node_id)
                              }}
                            >
                              {" "}
                              <i className=" fas fa-angle-right"></i>
                            </strong>
                          </div>
                          <div className="justify-content-between mt-2 d-flex">
                            <p className="font-size-15 fw-400 text-dark">
                              Questions Completed
                            </p>{" "}
                            <p className="m-0 font-size-15 fw-700 text-dark">
                              {activity.question_attended != "" &&
                              activity.question_attended != ""
                                ? parseInt(activity.question_attended)
                                : 0}
                            </p>
                          </div>
                          <div className="justify-content-between d-flex mt-2">
                            <p className="font-size-15 fw-400 text-dark">
                              Current mastery score
                            </p>{" "}
                            <p
                              className={` m-0 ${
                                activity.masteryscore >= 70
                                  ? "text-success"
                                  : activity.masteryscore >= 30 &&
                                    activity.masteryscore <= 69
                                  ? "text-warning"
                                  : activity.masteryscore <= 30 &&
                                    activity.masteryscore >= 1
                                  ? "text-danger"
                                  : ""
                              }`}
                            >
                              {activity.masteryscore != "" &&
                              activity.masteryscore != ""
                                ? activity.masteryscore
                                : "No Score"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 1 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].name != ""
                            ? activity.activity_node[0].name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 2 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].topic_name != ""
                            ? activity.activity_node[0].topic_name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 3 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].name != ""
                            ? activity.activity_node[0].name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 4 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].name != ""
                            ? activity.activity_node[0].name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 5 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].name != ""
                            ? activity.activity_node[0].name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 6 &&
                    activity.fk_sub_id === 1 &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].video_name != ""
                            ? activity.activity_node[0].video_name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 6 &&
                    activity.fk_sub_id === 2 &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].video_name != ""
                            ? activity.activity_node[0].video_name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else if (
                    activity.activity_type === 7 &&
                    (activity.fk_sub_id === 1 || activity.fk_sub_id === 2) &&
                    activity.activity_node.length > 0
                  ) {
                    return (
                      <div
                        className="bg-white"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <h5 className="mt-2">
                          {activity.activity_node &&
                          activity.activity_node.length > 0 &&
                          activity.activity_node[0].file_name != ""
                            ? activity.activity_node[0].file_name
                            : null}
                        </h5>
                        <div className="justify-content-between d-flex">
                          <strong>
                            {activity.activity_node &&
                            activity.activity_node.length > 0 &&
                            activity.activity_node[0].level_name &&
                            activity.activity_node[0].level_name != ""
                              ? activity.activity_node[0].level_name
                              : null}
                          </strong>{" "}
                          <strong
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleRightCanvasTopicGraph()
                              setTopicId(activity.ms_topic_id)
                              setNodeId(activity.fk_activity_node_id)
                            }}
                          >
                            {" "}
                            <i className="me-3 fas fa-angle-right"></i>
                          </strong>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Questions Completed</p>{" "}
                          <p className="me-3">
                            {activity.question_attended != "" &&
                            activity.question_attended != ""
                              ? parseInt(activity.question_attended)
                              : 0}
                          </p>
                        </div>
                        <div className="justify-content-between d-flex">
                          <p>Current mastery score</p>{" "}
                          <p
                            className={`me-3 ${
                              activity.masteryscore >= 70
                                ? "text-success"
                                : activity.masteryscore >= 30 &&
                                  activity.masteryscore <= 69
                                ? "text-warning"
                                : activity.masteryscore <= 30 &&
                                  activity.masteryscore >= 1
                                ? "text-danger"
                                : ""
                            }`}
                          >
                            {activity.masteryscore != "" &&
                            activity.masteryscore != ""
                              ? activity.masteryscore
                              : "No Score"}
                          </p>
                        </div>
                      </div>
                    )
                  } else {
                    return // or any other JSX you want to render for other activity types
                  }
                })
              ) : (
                <p>No Data</p>
              )}
            </div>
          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={isRightTopicGraph}
          direction="end"
          toggle={toggleRightCanvasTopicGraph}
        >
          <OffcanvasHeader
            toggle={toggleRightCanvasTopicGraph}
            className="border-bottom off-can-header cp-2"
          >
            <div
              className="d-flex align-items-center"
              onClick={toggleRightCanvasTopicGraph}
            >
              <div className="short-ic">
                <img className="bc-bg" src={chevleft} />
              </div>
              <p className="student_label_id font-size-12 ">Back</p>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="p-0">
            <div className="alltopic-wrap white-contrast p-1">
              <div>
                <label className="student_label2 m-0 font-size-12 fw-400 mb-02">
                  {TopicGraphDeatils
                    ? TopicGraphDeatils.subject
                      ? TopicGraphDeatils.subject
                      : null
                    : null}
                </label>
                {TopicGraphDeatils ? (
                  TopicGraphDeatils.topic ? (
                    <>
                      <p className="mb-02 font-size-16 text-dark lh-sm">
                        {TopicGraphDeatils
                          ? TopicGraphDeatils.topic
                            ? TopicGraphDeatils.topic
                            : null
                          : null}
                      </p>

                      <label className="student_label2 mb-02 font-size-12 ms-0 fw-400 opacity-50">
                        Questions Completed
                      </label>
                      <p className="mb-02 font-size-12 ms-0 fw-500 text-dark">
                        {TopicGraphDeatils
                          ? TopicGraphDeatils.total_question
                            ? TopicGraphDeatils.total_question
                            : null
                          : null}
                      </p>
                    </>
                  ) : null
                ) : null}
              </div>
              <div className="filter-section d-flex mb-4 flex-wrap">
                <div className="me-12">
                  <label>Date</label>
                  <br />
                  <DateRangePicker
                    format="dd-MM-yyyy"
                    value={dateValue}
                    onChange={handleDateChange}
                    cleanable={false}
                    className="custom-dropdown"
                    style={{ zIndex: 5050 }}
                  />
                </div>
              </div>
              {TopicGraphDeatils ? (
                TopicGraphDeatils.topic ? (
                  <>
                    <div className="chart-wrapper">
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div>
                            <p className="mb-02 font-size-12 fw-400 text-dark">
                              Mastery score over time
                            </p>
                            <p className="mb-02 mt-0 font-size-16 fw-400 text-dark">
                              {TopicGraphDeatilsValueData.length > 0
                                ? // ? TopicGraphDeatilsValueData.reduce(
                                  //     (total, currentValue) => total + currentValue,
                                  //     0
                                  //   )
                                  TopicGraphDeatilsValueData.join(",  ")
                                : 0}
                            </p>
                          </div>
                        </div>
                        <div style={{ marginBottom: "20px" }} className="me-2">
                          {/* <DatePicker
                      placeholder={"Select Date"}
                      selected={startDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM"
                      showMonthYearPicker
                      className="justify-content-end"
                      onKeyDown={e => {
                        e.preventDefault()
                      }}
                    /> */}

                          <div>
                            {startDate != null && startDate != "" ? (
                              <button className="" onClick={handelDateReset}>
                                <i
                                  class="mdi  mdi-close-circle"
                                  style={{ color: "#979797", fontSize: "20px" }}
                                ></i>
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <Line data={data_area_chart} />
                    </div>
                    <br></br>
                  </>
                ) : (
                  "Activity not available"
                )
              ) : null}
            </div>
          </OffcanvasBody>
        </Offcanvas>

        {/* //modal */}

        <Modal isOpen={modal_center} toggle={tog_center} size="xl" centered>
          <ModalHeader
            className="mt-0"
            toggle={tog_center}
            style={{ padding: "1rem" }}
          >
            <label className="font-size-18 fw-700 text-dark">
              View past sessions
            </label>
          </ModalHeader>
          <ModalBody>
            <TableComm
              loading={loading}
              columns={columns1}
              defaultId={"sest_name"}
              data={PastSessionStuData}
              //onRowClick={handleStudentViewClick}
              //hiddenColumns={['visibility', 'student_key']}
            />
          </ModalBody>
        </Modal>
      </Container>
    </div>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// };

export default withTranslation()(StudentList)
